// import { clientsClaim } from "workbox-core";
import { setCacheNameDetails } from "workbox-core";
import { precacheAndRoute } from "workbox-precaching";
import { registerRoute } from "workbox-routing";
import { CacheFirst } from "workbox-strategies";
// import { ExpirationPlugin } from "workbox-expiration";

self.__WB_DISABLE_DEV_LOGS = true;

const version = "v1";

// clientsClaim();

setCacheNameDetails({
  prefix: "workbox",
  precache: "precache",
  suffix: version,
});

precacheAndRoute(["/manifest.webmanifest"]);

registerRoute(
  /.*\.(?:png|jpg|jpeg|svg|gif)/,
  new CacheFirst({ cacheName: `workbox:images-${version}` })
);

// registerRoute(
//   /.*\.(?:js|json)/,
//   new NetworkFirst({
//     cacheName: `workbox:js-${version}`,
//     networkTimeoutSeconds: 5,
//     plugins: [
//       new ExpirationPlugin({
//         maxEntries: 50,
//         maxAgeSeconds: 5 * 60, // 5 minutes
//       }),
//     ],
//   })
// );

// self.addEventListener("install", function (event) {
//   // Perform install steps
//   console.log("e", event);
// });

self.addEventListener("activate", (event: any) => {
  event.waitUntil(
    caches
      .keys()
      .then((keys) =>
        Promise.all(
          keys.map((key) => {
            if (!key.includes(version)) {
              return caches.delete(key);
            }
          })
        )
      )
      .then(() => {
        // console.log("old Cache cleared");
      })
  );
});
